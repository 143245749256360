import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  focus: any;
  focus1: any;
  displayStyle="none"
  remainingText;
  numberOfCharacters2 = 0;
  maxNumberOfCharacters=400
  interaction = {
    textValue: ''
  };
  contactForm:FormGroup
  constructor(
     private service:ServiceService,
     private fb: FormBuilder) {
      this.contactForm = this.fb.group({
          name:['',Validators.compose([Validators.required,Validators.maxLength(40)])],
          email: ['', Validators.compose([Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'), Validators.minLength(1)])],
          company: ['',Validators.compose([Validators.required,Validators.maxLength(50)])],
          message: ['',Validators.compose([Validators.required,Validators.pattern('[a-zA-Z0-9 ,.?\\-]+'),Validators.maxLength(400)])],
          mobile: ['',Validators.compose([Validators.required, Validators.pattern("^[0-9]*$"),Validators.maxLength(15)])],
          
    
      })
   }
   get f() { return this.contactForm.controls; }

  ngOnInit() {}
  // valueChange(value) {
  //   this.remainingText = 400 - value;
  //  }
  onModelChange(textValue: string): void {
    this.numberOfCharacters2 = textValue.length;
  }
  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.contactForm.reset()
    this.displayStyle = "none";
  }
  onSubmit() {
    this.service.contactus(this.contactForm.value).subscribe(
        result => {
          console.log(this.contactForm.value)
          console.log(result)
          alert('Your Request has been send to Sales Team');
          //   this.contactForm.reset()
            window.location.reload()
        },
        err => {
  
  
        }
      )
    

   
    

}


}
