import { Component, OnInit, ViewChild,AfterViewInit ,ElementRef} from '@angular/core';

import { Router, NavigationEnd, ActivatedRoute } from '@angular/router'
import { FormBuilder,Validators, FormGroup } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-iccmenu',
  templateUrl: './iccmenu.component.html',
  styleUrls: ['./iccmenu.component.css']
})
export class IccmenuComponent implements AfterViewInit {
  videoLoaded_one: any = 0;
  videoLoaded_two: any = 0;
  videoLoaded_three: any = 0;
  videoLoaded_four: any = 0;
  displayStyle="none";
  numberOfCharacters2 = 0;
  maxNumberOfCharacters=400
  interaction = {
    textValue: ''
  };
 contactForm:FormGroup
  constructor( private activeRoute: ActivatedRoute,private service: ServiceService, private fb: FormBuilder) {
    this.contactForm = this.fb.group({
      name:['',Validators.compose([Validators.required,Validators.maxLength(40)])],
      email: ['', Validators.compose([Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'), Validators.minLength(1)])],
      company: ['',Validators.compose([Validators.required,Validators.maxLength(50)])],
      message: ['',Validators.compose([Validators.required,Validators.pattern('[a-zA-Z0-9 ,.?\\-]+'),Validators.maxLength(400)])],
      mobile: ['',Validators.compose([Validators.required, Validators.pattern("^[0-9]*$"),Validators.maxLength(15)])],


  })
  }

  @ViewChild('container') container: ElementRef<HTMLElement>;

  // var video = document.getElementById('banner-video');
  //   video.onended = function(e) {
  //     document.getElementById("intro-video").style.display = "none";
  //   };
  get f() { return this.contactForm.controls; }
  ngAfterViewInit(): void {
    this.activeRoute.params.subscribe(param => {
      // alert(param.pageSec)
      if(param.pageSec){
        const section = this.container.nativeElement.querySelector(`#${param.pageSec}`)
        console.log(section)

        section?.scrollIntoView()
      }
    })
  }
  onSubmit() {
    this.service.contactus(this.contactForm.value).subscribe(
        result => {
          console.log(this.contactForm.value)
          console.log(result)
          alert('Your Request has been send to Sales Team');
          //   this.contactForm.reset()
            window.location.reload()
        },
        err => {


        }
      )
}
  videoEnd(id) {

    if(id == 1){
      this.videoLoaded_one = 1;
    }else if(id == 2){
      this.videoLoaded_two = 1;
    }else if(id == 3){
      this.videoLoaded_three = 1;
    }else if(id == 4){
      this.videoLoaded_four = 1;
    }
  }
  videoPlay(id){
    //alert(id)
    if(id == 1){
      this.videoLoaded_one = 0;
    }else if(id == 2){
      this.videoLoaded_two = 0;
    }else if(id == 3){
      this.videoLoaded_three = 0;
    }else if(id == 4){
      this.videoLoaded_four = 0;
    }
  }
  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.contactForm.reset()
    this.displayStyle = "none";
  }

}

